// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
let RECAPTCHA_SITE_KEY = "6Ld8Z_8pAAAAAEZrSxg4gTPpvNW-1aETv55aQkH7";
let TOKEN = "eyJ4NXQiOiJNell4TW1Ga09HWXdNV0kwWldObU5EY3hOR1l3WW1NNFpUQTNNV0kyTkRBelpHUXpOR00wWkdSbE5qSmtPREZrWkRSaU9URmtNV0ZoTXpVMlpHVmxOZyIsImtpZCI6Ik16WXhNbUZrT0dZd01XSTBaV05tTkRjeE5HWXdZbU00WlRBM01XSTJOREF6WkdRek5HTTBaR1JsTmpKa09ERmtaRFJpT1RGa01XRmhNelUyWkdWbE5nX1JTMjU2IiwiYWxnIjoiUlMyNTYifQ.eyJzdWIiOiJhZG1pbiIsImF1dCI6IkFQUExJQ0FUSU9OIiwiYXVkIjoiNmhNMF9DVDFxSjZPMGhsUnJKNXNubGs3azA4YSIsIm5iZiI6MTcyNzgzODMyNCwiYXpwIjoiNmhNMF9DVDFxSjZPMGhsUnJKNXNubGs3azA4YSIsInNjb3BlIjoiZGVmYXVsdCIsImlzcyI6Imh0dHBzOlwvXC9hcGktdGVzdC5waW9uZWVyLmNvbS5waDo5NDQzXC9vYXV0aDJcL3Rva2VuIiwiZXhwIjo5MjIzMzcyMDM2ODU0Nzc1LCJpYXQiOjE3Mjc4MzgzMjQsImp0aSI6ImEwZTYwOWIwLTljMWQtNDlkNi1iNjZlLTc1ZDFiYmRjY2Q2ZiJ9.ofQz4Tgj-YGsCzLbTpjr_oQUVFD6UbjwdhQYoYiCCPoRDcpFIUfxU1NJdZsJQ6Y1U51W9EM6gJPqRZThkJGpwG_hhDxv0KbniUV7Mbbcvx21-2nKm6BpUm5KgmKlni0kvs64KqflrBZ4xAQ5JSUquJZbT8orHUDPCiPvDcM7TXOQtX3mdWcrm82fdWsfF0WlceGon-80PeItWeqHgcyRjb7uGewQC7xwDmFKBnnlGRUZgSOMXjDGUJ7BqIpELdFD88_83ylN8SBkXo4C3ZwAOmZxuOtDn9wxJ3ww_o40l8i0mNCKt8f1HmNiQ6XdE8RdZZbSvzwO_TYMZhx6EH3OBg";
let defaultISSPrefix = "ISSP";

// CTPL FAQS
let ctplDocs = "https://dev.pioneerinsureshop.com/api/v1/admin/file/download/CTPL_FAQ.pdf";

let keycloakConfig = {
  clientId: 'insureshop-app',
  realm: 'insureshop',
  url: 'https://keycloak.pioneerinsureshop.com/auth',
  credentials: {
    secret: '5d9dc648-7067-4268-b902-b58520f6b711'
  }
}

let externalAppConfig = {
  url: 'https://accihealth-ui.test.service.pioneerinsureshop.com',
  motorProducts: 'https://accihealth-ui.test.service.pioneerinsureshop.com/motor/products'
}

let accihealthConfig = {
  url: 'https://accihealth-core.test.service.pioneerinsureshop.com',
  hospicash: 'https://accihealth-ui.test.service.pioneerinsureshop.com/product/hospicash',
  ooopsie: 'https://accihealth-ui.test.service.pioneerinsureshop.com/product/ooopsie'
}

let ctplConfig = {
  url: 'https://ctpl-core.test.service.pioneerinsureshop.com',
  ctpl: 'https://accihealth-ui.test.service.pioneerinsureshop.com/motor/ctpl',
  motorProducts: 'https://accihealth-ui.test.service.pioneerinsureshop.com/motor/products'
}

let motorCompreConfig = {
  url: 'https://dev.pioneerinsureshop.com'
}

let petConfig = {
  url: 'http://petpioneer.ph/'
}

let ofwConfig = {
  url: 'https://products.pioneer.com.ph/OFWBM/'
}

let homeConfig = {
  url: 'https://products.pioneer.com.ph/homeinsurance/'
}

let buhayConfig = {
  url: 'https://products.pioneer.com.ph/bundledbuhay/select-product?agtno=URpnN23ObDkequal',
  ospital: 'https://products.pioneer.com.ph/bundledbuhay/select-product?agtno=URpnN23ObDkequal',
  bahay: 'https://products.pioneer.com.ph/bundledbuhay/select-product?agtno=URpnN23ObDkequal',
  aksidente: 'https://products.pioneer.com.ph/bundledbuhay/select-product?agtno=URpnN23ObDkequal'
}

let sureDayTripConfig = {
  url: 'https://products.pioneer.com.ph/Travel/registration'
}

let defautEmails = {
  pli: "cashier@insureshop.com.ph",
  pisc: "pisccashierlink_insureshop@pioneer.com.ph",
  piic: "piiccashierlink_insureshop@pioneer.com.ph"
}

// breadcrumb content
let breadcrumbConfig =  [
  {
    route: '/motor-car-comprehensive',
    stack: [
      {
        name: 'Motor Car and Motorcycle Insurance',
        path: externalAppConfig.motorProducts,
        external: true
      },
      {
        name: 'Motorcar',
        path: externalAppConfig.motorProducts,
        external: true
      },
      {
        name: 'Comprehensive Insurance',
        path: externalAppConfig.motorProducts,
        external: true
      },
      {
        name: 'Learn More',
        path: '',
        external: false
      },
    ]
  }
]

export const environment = {
  production: false,
  keycloakConfig: keycloakConfig,
  accihealthConfig: accihealthConfig,
  ctplConfig: ctplConfig,
  motorCompreConfig: motorCompreConfig,
  petConfig: petConfig,
  ofwConfig: ofwConfig,
  homeConfig: homeConfig,
  buhayConfig: buhayConfig,
  sureDayTripConfig: sureDayTripConfig,
  defautEmails: defautEmails,
  idleTimeInMinutes: 15,
  sessionTimeoutInMinutes: 15,
  sessionTimeoutReminderInMinutes: 5,
  otpResendLimit: 5,
  otpCountdownInSeconds: 120,
  issPrefix: defaultISSPrefix,
  externalAppConfig: externalAppConfig,
  breadcrumbConfig: breadcrumbConfig,
  recaptchaSiteKey: RECAPTCHA_SITE_KEY,
  apiToken: TOKEN,
  ctplFaqsFile: ctplDocs
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
