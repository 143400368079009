import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OTP, Generic} from '@insureshop/shared/models';
import { Observable } from 'rxjs';
import { AesService } from '@insureshop/core/services/aes.service';
import { environment } from '@env/environment';
import * as moment from 'moment';
import { Moment } from 'moment';

@Injectable()
export class UtilService {
  obj : Generic;
  constructor(private httpClient: HttpClient, public aes: AesService) {}

  public enumToArray(obj: any): any[] {
    return Object.keys(obj).map(key => ({ value: key, label: obj[key] }));
  }

  public sendOtp(contactDetails: OTP): Observable<OTP> {
    if(contactDetails.mobileNumber !== null){
      contactDetails.mobileNumber = '63' + contactDetails.mobileNumber;
    }
    return this.httpClient.post<OTP>(
      `/api/v1/admin/otp`,
      contactDetails,
      { headers: { exclude: 'true' } }
    );
  }

  public sendOtpForUpdate(): Observable<OTP> {
    return this.httpClient.post<OTP>(
      `/api/v1/admin/otp`,
      { headers: { exclude: 'true' } }
    );
  }

  public sendOtp2(gen: Generic): Observable<OTP> {
    return this.httpClient.post<OTP>(
      `/api/v1/admin/otp/secure`,
      gen,
      { headers: { exclude: 'true' } }
    );
  }

  public verifyOtp(id: number, code: string): Observable<any> {
    return this.httpClient.post<any>(
      `/api/v1/admin/otp/${id}/code/${code}/verify`,
      null,
      { headers: { exclude: 'true' } }
    );
  }

  /**
   * @description - an API that generates OTP
   *
   * @param email - email where to send OTP
   * @param mobileNo - mobile number where to send OTP
   * @param reference - reference ID
   */
  public generateOtp(email: string, mobileNo: string, reference:string) {
    const payload =  {
      email,
      mobileNo,
      reference
    }
    return this.httpClient.post<any>(
      `${environment.motorCompreConfig.url}/hodu/service-quotation/v1.0/otp/generate`,
      payload
    );
  }

  /**
   * @description an API that validates OTP
   *
   * @param otp - generated OTP sent via API
   * @param ref - reference ID from OTP generation
   */
  public validateOtp(otp: string, ref: string) {
    let parameters = new HttpParams();
    parameters = parameters
      .set('otp', otp)
      .set('reference', ref);
    return this.httpClient.post<any>(
      `${environment.motorCompreConfig.url}/hodu/service-quotation/v1.0/otp/validate`,
      null,{
        params: parameters
      }
    );
  }

  public randomNumber(num: number) {
    return Math.floor(Math.random() * Math.floor(num));
  }

  /**
   * @description a function to determine whether device used is mobile or not
   *
   */
  isMobile() {

    // via useragent detection
    const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    const isMobileUserAgent = regex.test(navigator.userAgent);

    // via feature detection
    const hasTouchSupport = 'ontouchstart' in window || navigator.maxTouchPoints > 0;

    if((!isMobileUserAgent && hasTouchSupport) || (isMobileUserAgent && hasTouchSupport)) {
      // mobile
      return true;
    } else {
      // desktop
      return false;
    }
  }

  /**
   * @description function for filtering duplicate entries in an array
   *
   * @param data - object to filter
   * @param key - property name for checking duplicate
   */
  filterDuplicate(data: any[], key: string) {
    return data.filter((item, index, self) => self.findIndex(t => t[key] === item[key]) === index);
  }

  /**
   * @description function for filtering an array of objects by key and value
   *
   * @param data - object array to filter
   * @param key - property name to filter
   * @param value - value to match
   */
  filterByKey(data: any[], key: string, value: any) {
    return data.filter((item) => item[key] === value);
  }

  /**
   * @description function for generating a range of years
   *
   * @param totalYears - length of array
   */
  getYearRange(totalYears: number): string[] {
    const currentYear = new Date().getFullYear();
    const startYear = currentYear - totalYears;
    const yearRange: string[] = [];

    for (let i = startYear; i <= currentYear; i++) {
      yearRange.push((i).toString());
    }

    return yearRange;
  }

  /**
   * @description function for calculating car age
   *
   * @param value - value to check
   */
  getCarAge(value: any): number {
    const currentYear = new Date().getFullYear();
    const carYear = parseInt(value);
    return currentYear - carYear;
  }

  /**
   * @description function for sorting an array.
   *
   * @param data - array to sort
   * @param key - key to sort for object array
   */
  sortData(data: any, key?: string) {
    if (key) {
      return data.slice().sort((a, b) => {
        if (a[key] < b[key]) {
          return -1;
        }
        if (a[key] > b[key]) {
          return 1;
        }
        return 0;
      });
    } else {
      // Sort without referencing a specific key
      return data.slice().sort();
    }
  }

  /**
   * @description function for getting value from an array
   *
   * @param options - array to filter
   * @param value - value to match
   * @param key - key to access for object array
   */
  dropdownStringValue(options: any[], value: any, key?: string) {
    const stringValue = options.filter((x) => {
      if (key) {
        return x[key] === value;
      } else {
        return x === value;
      }
    });

    return stringValue[0];
  }

  /**
   * @description function for checking if an object is empty.
   *
   * @param obj - object to check
   */
  isObjectEmpty(obj: any) {
    return !Object.keys(obj).length;
  }

  formatToNumeric(value: string | number): number {
    if (typeof value === 'number') {
      return value; // return the number directly if it's already a number
    }

    const numericValue = value.replace(/[^\d.-]/g, ''); // removes non-numeric characters
    return parseFloat(numericValue);
  }

  getMomentDateTime(date: string, time: string): Moment {
    return moment(`${date} ${time}`, 'MMMM DD, YYYY h:mm A');
  }

  compareDates(dateTime1: Moment, dateTime2: Moment): boolean {
    return dateTime1.isBefore(dateTime2);
  }
}
