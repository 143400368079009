import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MbscDatetimeOptions } from '@mobiscroll/angular';
import { Moment } from 'moment';

import * as moment from 'moment';

@Component({
  selector: 'app-timepicker',
  templateUrl: './timepicker.component.html',
  styleUrls: ['./timepicker.component.scss']
})
export class TimepickerComponent implements OnInit {
  @Input() customClass: string = '';
  @Input() min: Moment = moment();
  @Input() max: Moment = moment();
  @Input() control: FormControl;
  @Input() placeholder: string = '';
  @Input() required: boolean = true;
  @Input() timeHeader: string;
  @Output() set: EventEmitter<any> = new EventEmitter();
  @Output() timeChange: EventEmitter<any> = new EventEmitter();

  timeSettings: MbscDatetimeOptions;

  constructor() {}

  ngOnChanges() {

    this.timeSettings = {
      theme: 'bootstrap',
      animate: 'pop',
      display: 'center',
      touchUi: false,
      buttons: ['set'],
      showLabel: false,
      headerText: this.timeHeader,
      defaultValue: null,
      returnFormat: 'locale',
      min: this.min.toDate(),
      max: this.max.toDate()
    };
  }

  ngOnInit() {}

  timeInputChange(value: any) {
    this.timeChange.emit(value);
  }

}
